import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('user-list-add-new',{attrs:{"role-options":_vm.roleOptions},on:{"refetch-data":_vm.fetchItems},model:{value:(_vm.isAddNewUserSidebarActive),callback:function ($$v) {_vm.isAddNewUserSidebarActive=$$v},expression:"isAddNewUserSidebarActive"}}),_c(VRow,{staticClass:"mb-5"},_vm._l((_vm.userTotalLocal),function(total){return _c(VCol,{key:total.total,attrs:{"cols":"12","sm":"6","md":"3"}},[_c(VCard,[_c(VCardText,{staticClass:"d-flex align-center justify-space-between pa-4"},[_c('div',[_c('h2',{staticClass:"font-weight-semibold mb-1"},[_vm._v(" "+_vm._s(total.total)+" ")]),_c('span',[_vm._v(_vm._s(total.title))])]),_c(VAvatar,{class:("v-avatar-light-bg " + (_vm.resolveUserTotalIcon(total.title).color) + "--text"),attrs:{"color":_vm.resolveUserTotalIcon(total.title).color}},[_c(VIcon,{staticClass:"rounded-0",attrs:{"size":"25","color":_vm.resolveUserTotalIcon(total.title).color}},[_vm._v(" "+_vm._s(_vm.resolveUserTotalIcon(total.title).icon)+" ")])],1)],1)],1)],1)}),1),_c(VCard,[_c(VCardTitle,[_c(VRow,[_c(VCol,[_vm._v(" Search & Filter ")]),_c(VCol,{staticClass:"pb-0",attrs:{"align":"end"}},[(_vm.$can('create', 'User'))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"fab":"","x-small":"","elevation":"4","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.isAddNewUserSidebarActive = !_vm.isAddNewUserSidebarActive}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,3096700207)},[_c('span',[_vm._v("Add New User")])]):_vm._e()],1)],1)],1),_c('vuex-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.users,"options":_vm.options,"search":_vm.search,"meta":_vm.meta,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [5, 10, 25, 50, 100]
      }},on:{"update:search":function($event){_vm.search=$event},"update:meta":function($event){_vm.meta=$event},"update:options":function (o) { return _vm.options = o; }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[(item.avatar)?_c(VImg,{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.name)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('div',{staticClass:"text--primary font-weight-semibold text-truncate text-decoration-none"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('small',[_vm._v(_vm._s(item.email))])])],1)]}},{key:"item.role",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:("v-avatar-light-bg " + (_vm.resolveUserRoleVariant(item.role)) + "--text me-3"),attrs:{"size":"30","color":_vm.resolveUserRoleVariant(item.role)}},[_c(VIcon,{attrs:{"size":"18","color":_vm.resolveUserRoleVariant(item.role)}},[_vm._v(" "+_vm._s(_vm.resolveUserRoleIcon(item.role))+" ")])],1),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.role))])],1)]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(_vm.$can('destroy', 'User'))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error","disabled":_vm.currentUserId === item.id || _vm.deleting,"loading":_vm.isDialogOpen && _vm.selectedUser === item},on:{"click":function($event){return _vm.selectUserToDelete(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete User")])]):_vm._e()]}}],null,true)})],1),(_vm.$can('destroy', 'User'))?_c('user-delete-dialog',{attrs:{"data":_vm.selectedUser,"deleting":_vm.deleting,"is-dialog-open":_vm.isDialogOpen},on:{"update:isDialogOpen":function($event){_vm.isDialogOpen=$event},"update:is-dialog-open":function($event){_vm.isDialogOpen=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }