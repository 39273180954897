import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiDnsOutline,
} from '@mdi/js'
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { computed, getCurrentInstance, readonly, ref, toRaw, watch } from '@vue/composition-api'

export default function useUsersList() {
  const vm = getCurrentInstance().proxy

  const userListTable = ref([])

  const tableColumns = [
    { text: 'USER', value: 'name', width: '50%' },
    { text: 'EMAIL', value: 'email', width: '20%' },
    {
      text: 'ROLE',
      value: 'role',
      sortable: false,
      width: '10%',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
      width: '20%',
    },
  ]

  const users = computed({
    get: () => vm.$store.getters['users/items'],
  })
  const loading = computed({
    get: () => vm.$store.getters['users/loading'],
  })
  const meta = computed({
    get: () => vm.$store.getters['users/meta'],
  })
  const filters = computed({
    get: () => vm.$store.getters['users/filters'],
  })
  const roleFilter = computed({
    get: () => vm.$store.getters['users/filters']?.role,
    set: val => vm.$store.dispatch('users/setFilters', { ...filters.value, role: val }),
  })
  const options = computed({
    get: () => vm.$store.getters['users/options'],
    set: val => vm.$store.dispatch('users/setOptions', { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters['users/search'],
    set: val => vm.$store.dispatch('users/setSearch', val),
  })
  const totalUserListTable = computed({
    get: () => vm.$store.getters['users/meta'].total,
  })

  const fetchItems = () => vm.$store.dispatch('users/fetchItems')
  const setOptions = val => vm.$store.dispatch('users/setOptions', val)
  const setFilters = val => vm.$store.dispatch('users/setFilters', val)

  const init = () => {
    fetchItems()
  }

  const destroy = () => {}

  const resolveUserRoleVariant = role => {
    if (role === 'admin') return 'error'
    if (role === 'user') return 'primary'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'admin') return mdiDnsOutline
    if (role === 'user') return mdiAccountOutline

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'

    return 'primary'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    userListTable,
    tableColumns,

    totalUserListTable,
    loading,

    fetchItems,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserTotalIcon,

    meta,
    users,
    search,
    options,
    filters,

    roleFilter,

    setOptions,
    setFilters,

    init,
    destroy,
  }
}
