import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"value":_vm.isAddNewUserSidebarActive,"temporary":"","touchless":"","right":!_vm.$vuetify.rtl,"width":_vm.$vuetify.breakpoint.smAndUp ? 350 : '100%',"app":""},on:{"input":function (val) { return _vm.$emit('update:is-add-new-user-sidebar-active', val); }}},[_c(VCard,{attrs:{"height":"100%"}},[_c('div',{staticClass:"drawer-header d-flex align-center mb-4"},[_c('span',{staticClass:"font-weight-semibold text-base text--primary"},[_vm._v(" "+_vm._s(_vm.t('Add New User'))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('update:is-add-new-user-sidebar-active',false)}}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c(VTextField,{staticClass:"mb-6",attrs:{"regular":"","rules":[_vm.validators.required],"label":_vm.t('Full Name'),"name":"name","hide-details":"auto","error-messages":_vm.errorMessages.name}}),_c(VTextField,{staticClass:"mb-6",attrs:{"rules":[_vm.validators.required,_vm.validators.emailValidator],"type":"email","name":"email","label":_vm.t('Email'),"placeholder":"Email","hide-details":"auto","regular":"","error-messages":_vm.errorMessages.email}}),_c(VBtn,{staticClass:"me-3",attrs:{"loading":_vm.loading,"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.t('Add'))+" ")]),_c(VBtn,{attrs:{"disabled":_vm.loading,"color":"secondary","outlined":"","type":"reset"},on:{"click":_vm.resetForm}},[_vm._v(" "+_vm._s(_vm.t('Cancel'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }