<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">
          {{ t('Add New User') }}
        </span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            regular
            :rules="[validators.required]"
            :label="t('Full Name')"
            name="name"
            hide-details="auto"
            class="mb-6"
            :error-messages="errorMessages.name"
          ></v-text-field>

          <v-text-field
            :rules="[validators.required,validators.emailValidator]"
            type="email"
            name="email"
            :label="t('Email')"
            placeholder="Email"
            hide-details="auto"
            class="mb-6"
            regular
            :error-messages="errorMessages.email"
          ></v-text-field>

          <!-- <v-select
            :rules="[validators.required]"
            name="status"
            :label="t('Status')"
            :items="options.status"
            item-text="title"
            item-value="value"
            hide-details="auto"
            class="mb-6"
            regular
            :error-messages="errorMessages.status"
          >
          </v-select>

          <v-select
            :rules="[validators.required]"
            name="role"
            :label="t('Role')"
            :items="options.role"
            item-text="title"
            item-value="value"
            hide-details="auto"
            class="mb-6"
            regular
            :error-messages="errorMessages.role"
          >
          </v-select>

          <v-select
            :rules="[validators.required]"
            name="locale"
            :label="t('Locale')"
            :items="options.locale"
            item-text="title"
            item-value="value"
            hide-details="auto"
            class="mb-6"
            regular
            :error-messages="errorMessages.locale"
          >
          </v-select>

          <v-select
            :rules="[validators.required]"
            name="tz"
            :label="t('Timezone')"
            :items="options.tz"
            item-text="title"
            item-value="value"
            hide-details="auto"
            class="mb-6"
            regular
            :error-messages="errorMessages.tz"
          >
          </v-select> -->

          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ t('Add') }}
          </v-btn>
          <v-btn
            :disabled="loading"
            color="secondary"
            outlined
            type="reset"
            @click="resetForm"
          >
            {{ t('Cancel') }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
// eslint-disable-next-line no-unused-vars
import { ref, getCurrentInstance, computed } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { t } = useUtils()

    const form = ref(null)
    const errorMessages = ref([])

    const loading = computed({
      get: () => vm.$store.getters['users/creating'],
    })

    const resetForm = () => {
      form.value.reset()
      form.value.resetErrorBag()
      form.value.resetValidation()

      errorMessages.value = []

      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = () => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return

      const formData = new FormData(form.value.$el)

      vm.$store
        .dispatch('users/create', formData)
        .then(res => res && resetForm())
        .catch(err => {
          if (err?.data?.errors) {
            errorMessages.value = err.data.errors
          }
        })
    }

    const options = {
      role: [
        { title: t('User'), value: 'user' },
        { title: t('Admin'), value: 'admin' },
      ],
      status: [
        { title: t('Active'), value: 'active' },
        { title: t('Suspended'), value: 'suspended' },
      ],
      locale: [{ title: t('English'), value: 'en' }],
      tz: [{ title: t('Europe/Zagreb'), value: 'Europe/Zagreb' }],
    }

    return {
      form,
      loading,
      errorMessages,

      onSubmit,
      resetForm,

      options,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
      },

      t,
    }
  },
}
</script>
