<template>
  <div id="user-list">
    <!-- app drawer -->
    <user-list-add-new
      v-model="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      @refetch-data="fetchItems"
    ></user-list-add-new>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in userTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveUserTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveUserTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveUserTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveUserTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            Search &amp; Filter
          </v-col>
          <v-col
            align="end"
            class="pb-0"
          >
            <v-tooltip
              v-if="$can('create', 'User')"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  x-small
                  elevation="4"
                  color="primary"
                  @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive"
                  v-on="on"
                >
                  <v-icon>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Add New User</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>

      <!-- table -->
      <vuex-table
        :headers="tableColumns"
        :items="users"
        :options="options"
        :search.sync="search"
        :meta.sync="meta"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, 50, 100]
        }"
        @update:options="o => options = o"
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.avatar"
                :src="require(`@/assets/images/avatars/${item.avatar}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <div
                class="text--primary font-weight-semibold text-truncate text-decoration-none"
              >
                {{ item.name }}
              </div>
              <small>{{ item.email }}</small>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.role`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.role)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.role)}--text me-3`"
            >
              <v-icon
                size="18"
                :color="resolveUserRoleVariant(item.role)"
              >
                {{ resolveUserRoleIcon(item.role) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ item.role }}</span>
          </div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <!-- <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="me-1"
                v-bind="attrs"
                :to="{ name: 'user-view', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View User</span>
          </v-tooltip> -->

          <!-- TODO: HANDLE USER DELETE -->
          <v-tooltip
            v-if="$can('destroy', 'User')"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                color="error"
                v-bind="attrs"
                :disabled="currentUserId === item.id || deleting"
                :loading="isDialogOpen && selectedUser === item"
                @click="selectUserToDelete(item)"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete User</span>
          </v-tooltip>
        </template>
      </vuex-table>
    </v-card>

    <user-delete-dialog
      v-if="$can('destroy', 'User')"
      :data="selectedUser"
      :deleting="deleting"
      :is-dialog-open.sync="isDialogOpen"
    ></user-delete-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiEyeOutline, mdiPlus, mdiDeleteOutline, mdiExportVariant } from '@mdi/js'

import { useUtils as useAclUtils } from '@core/libs/acl'
// eslint-disable-next-line object-curly-newline
import { ref, computed, getCurrentInstance, onMounted, onUnmounted } from '@vue/composition-api'

import { avatarText } from '@core/utils/filter'
import UserListAddNew from './UserListAddNew.vue'
import useUsersList from './useUsersList'
import UserDeleteDialog from './UserDeleteDialog.vue'

export default {
  components: {
    UserListAddNew,
    UserDeleteDialog,
  },
  setup() {
    const { can } = useAclUtils()
    const vm = getCurrentInstance().proxy
    const currentUserId = computed(() => vm.$store.getters['auth/currentUserId'])

    const isDialogOpen = ref(false)

    const deleting = computed(() => vm.$store.getters['users/deleting'])
    const selectedUser = ref(null)
    const selectUserToDelete = user => {
      selectedUser.value = user
      isDialogOpen.value = !!user

      // console.error(selectedUser.value, isDialogOpen.value)
    }

    const {
      userListTable,
      tableColumns,
      totalUserListTable,
      loading,

      userTotalLocal,

      meta,
      search,
      filters,
      options,
      roleFilter,

      setOptions,
      setFilters,

      fetchItems,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,

      users,

      init,
      destroy,
    } = useUsersList()

    onMounted(() => {
      init()
    })
    onUnmounted(() => {
      destroy()
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'User', value: 'user' },
    ]

    const canDeleteUser = userId => can('destroy', 'User') && currentUserId.value !== userId

    return {
      userListTable,
      tableColumns,
      totalUserListTable,
      roleOptions,

      loading,
      userTotalLocal,
      isAddNewUserSidebarActive,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserTotalIcon,
      fetchItems,

      setOptions,
      setFilters,

      users,
      meta,
      search,
      filters,
      options,
      roleFilter,

      // icons
      icons: {
        mdiPlus,
        mdiEyeOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiExportVariant,
      },

      currentUserId,
      canDeleteUser,

      isDialogOpen,

      deleting,
      selectedUser,
      selectUserToDelete,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
